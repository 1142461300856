import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/templates/Main/index.tsx";
import Box from '@material-ui/core/Box';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Box padding={3} mdxType="Box">
      <p>{`TaleFin Pty Ltd (ACN: 644 262 742) is an information services business that recognises the importance of privacy and information security to effectively operate our business.  We operate within the bounds provided by the Australian Privacy Principles under the Privacy Act 1988 (Cth) (the Act).  This document articulates how we manage personal information in compliance with our obligations under the Act.`}</p>
    </Box>
    <Box padding={3} mdxType="Box">
      <h3 {...{
        "id": "what-is-our-commitment-to-privacy",
        "style": {
          "position": "relative"
        }
      }}>{`What is our commitment to privacy?`}<a parentName="h3" {...{
          "href": "#what-is-our-commitment-to-privacy",
          "aria-label": "what is our commitment to privacy permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h3>
      <p>{`We recognise your right to privacy and have established a number of systems to support:`}</p>
      <ul>
        <li parentName="ul">{`the security of your information `}</li>
        <li parentName="ul">{`the ability to access the information that we hold about you and; `}</li>
        <li parentName="ul">{`the ability to correct and improve the quality of any information that we hold about you.  `}</li>
      </ul>
      <p>{`We also operate a credit reporting business. For information about how we handle credit reporting information please see `}<a parentName="p" {...{
          "href": "https://www.talefin.com/talefin-credit-reporting-policy"
        }}>{`here`}</a>{`. `}</p>
    </Box>
    <Box padding={3} mdxType="Box">
      <h3 {...{
        "id": "what-kinds-of-information-do-we-collect",
        "style": {
          "position": "relative"
        }
      }}>{`What kinds of information do we collect?`}<a parentName="h3" {...{
          "href": "#what-kinds-of-information-do-we-collect",
          "aria-label": "what kinds of information do we collect permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h3>
      <p>{`We may collect and hold the following information: `}</p>
      <ul>
        <li parentName="ul">{`Identification information including: `}
          <ul parentName="li">
            <li parentName="ul">{`Full name `}</li>
            <li parentName="ul">{`Previous name or alias `}</li>
            <li parentName="ul">{`Address `}</li>
            <li parentName="ul">{`Previous address `}</li>
            <li parentName="ul">{`Date of birth `}</li>
            <li parentName="ul">{`Driver license number `}</li>
            <li parentName="ul">{`Other identifying document numbers `}</li>
          </ul>
        </li>
        <li parentName="ul">{`Consumer credit liability information including: `}
          <ul parentName="li">
            <li parentName="ul">{`Credit provider name `}</li>
            <li parentName="ul">{`Type of credit `}</li>
            <li parentName="ul">{`Term of credit contract `}</li>
          </ul>
        </li>
        <li parentName="ul">{`Repayment history `}</li>
        <li parentName="ul">{`Default information (Where applicable) `}</li>
        <li parentName="ul">{`Serious credit infringements (Where applicable) `}</li>
        <li parentName="ul">{`New arrangement information `}</li>
      </ul>
      <p>{`If you access our website we will record transactions conducted on the website including website tracking cookies and IP Addresses `}</p>
      <p>{`If you request us to access bank/financial information on your behalf, you will be asked to provide us with the name of your bank/financial institution, and your username and account log in details, so that the bank/financial institution can identify you to access your records and your account log in details`}</p>
      <p>{`We do NOT collect any of the following: `}</p>
      <ul>
        <li parentName="ul">{`Racial or ethnic origin `}</li>
        <li parentName="ul">{`Political opinions or associations `}</li>
        <li parentName="ul">{`Religious beliefs `}</li>
        <li parentName="ul">{`Sexual orientation or practices `}</li>
        <li parentName="ul">{`Criminal Records `}</li>
      </ul>
    </Box>
    <Box padding={3} mdxType="Box">
      <h3 {...{
        "id": "how-do-we-collect-and-hold-your-information",
        "style": {
          "position": "relative"
        }
      }}>{`How do we collect and hold your information?`}<a parentName="h3" {...{
          "href": "#how-do-we-collect-and-hold-your-information",
          "aria-label": "how do we collect and hold your information permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h3>
      <p>{`We collect credit reporting information about you fairly and lawfully in the course of running a credit reporting business from: `}</p>
      <ul>
        <li parentName="ul">{`Credit Providers `}</li>
        <li parentName="ul">{`Mortgage or Trade Insurers `}</li>
        <li parentName="ul">{`Mercantile Agencies `}</li>
      </ul>
      <p>{`Before providing your information, these businesses must seek and obtain consent from you. We will also collect information from you to verify your identity before providing access to your file and to submit corrections and bans. `}</p>
      <p>{`We also collect bank and financial information directly from you with your consent to help you to access credit. `}</p>
      <p>{`We are committed to keeping your personal information secure.  We are ISO-27001 (Information Security) complaint.  All of your data in encrypted in transit and at rest. `}</p>
    </Box>
    <Box padding={3} mdxType="Box">
      <h3 {...{
        "id": "who-we-disclose-your-information-to",
        "style": {
          "position": "relative"
        }
      }}>{`Who we disclose your information to`}<a parentName="h3" {...{
          "href": "#who-we-disclose-your-information-to",
          "aria-label": "who we disclose your information to permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h3>
      <p>{`We customarily do not disclose, or permit the disclosure of your personal information: `}</p>
      <ul>
        <li parentName="ul">{`(a) in a way that could be used to identify an individual member in any circumstances; or `}</li>
        <li parentName="ul">{`(b) to unrelated third parties, including to third party recipients overseas `}</li>
      </ul>
      <p>{`except as described in this Privacy Policy and our User Terms & Conditions, unless we have your permission, or such disclosure is incidental to your use of the Website, or where we are required by law or regulatory authority to do so. `}</p>
      <p>{`We disclose information in accordance with the Privacy Act to support customers to: `}</p>
      <ul>
        <li parentName="ul">{`Meet compliance obligations `}</li>
        <li parentName="ul">{`Assess risk when proving credit `}</li>
        <li parentName="ul">{`Protect you against fraud `}</li>
      </ul>
      <p>{`Customers who view your information do so only after having received consent from you either directly or through us on their behalf. `}</p>
      <p>{`We may also use aggregated / de-identified information to establish generalised insights about the broader population. `}</p>
    </Box>
    <Box padding={3} mdxType="Box">
      <h3 {...{
        "id": "how-to-access-your-information",
        "style": {
          "position": "relative"
        }
      }}>{`How to access your information`}<a parentName="h3" {...{
          "href": "#how-to-access-your-information",
          "aria-label": "how to access your information permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h3>
      <p>{`You may at any time access information that we have about you by: `}</p>
      <ul>
        <li parentName="ul">{`Identifying yourself via our website here `}</li>
        <li parentName="ul">{`Registering an account that will allow you to be identified in the future `}</li>
        <li parentName="ul">{`Logging in at any time to access your information. `}</li>
      </ul>
    </Box>
    <Box padding={3} mdxType="Box">
      <h3 {...{
        "id": "user-experience",
        "style": {
          "position": "relative"
        }
      }}>{`User experience`}<a parentName="h3" {...{
          "href": "#user-experience",
          "aria-label": "user experience permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h3>
      <p>{`At TaleFin, we take your privacy seriously.  `}</p>
      <p>{`We use Google Analytics to collect anonymous information about how visitors use our website and application. Google Analytics uses cookies to collect this information, which is then used to generate reports about user activity on our website and application.
The information collected by Google Analytics may include: `}</p>
      <ul>
        <li parentName="ul"><em parentName="li">{`Your device's IP address`}</em>{` `}</li>
        <li parentName="ul"><em parentName="li">{`Your device's operating system and browser type`}</em>{` `}</li>
        <li parentName="ul"><em parentName="li">{`The web pages you visit on our website`}</em></li>
        <li parentName="ul"><em parentName="li">{`The date and time of your visit`}</em></li>
        <li parentName="ul"><em parentName="li">{`The amount of time you spend on each page`}</em></li>
        <li parentName="ul"><em parentName="li">{`Your geographic location`}</em></li>
      </ul>
      <p>{`We use this information to understand how visitors use our services and to make improvements to content and functionality. Your personal information is kept secure and separate from the tracking data. By using our website, you consent to the processing of data about you by Google in the manner described in Google's Privacy Policy and for the purposes set out above.
You can opt-out of Google Analytics if you disable or refuse the cookie, disable JavaScript, or use the opt-out service provided by Google. `}</p>
    </Box>
    <Box padding={3} mdxType="Box">
      <h3 {...{
        "id": "updates-to-this-policy",
        "style": {
          "position": "relative"
        }
      }}>{`Updates to this policy`}<a parentName="h3" {...{
          "href": "#updates-to-this-policy",
          "aria-label": "updates to this policy permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h3>
      <p>{`We may update this Privacy Policy occasionally. By continuing to use our website after changes are made, you agree to the revised policy. We encourage you to review it regularly before providing any personal information to ensure you're comfortable with any updates. `}</p>
    </Box>
    <Box padding={3} mdxType="Box">
      <h3 {...{
        "id": "how-to-get-in-contact-with-us",
        "style": {
          "position": "relative"
        }
      }}>{`How to get in contact with us`}<a parentName="h3" {...{
          "href": "#how-to-get-in-contact-with-us",
          "aria-label": "how to get in contact with us permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h3>
      <p>{`If you have any questions or concerns about this Privacy Policy, please reach out to us at `}<a parentName="p" {...{
          "href": "mailto:info@talefin.com."
        }}>{`info@talefin.com.`}</a>{` We're here to help with any inquiries you may have.`}</p>
    </Box>
    <Box padding={3} mdxType="Box">
      <h3 {...{
        "id": "how-to-correct-your-information",
        "style": {
          "position": "relative"
        }
      }}>{`How to correct your information`}<a parentName="h3" {...{
          "href": "#how-to-correct-your-information",
          "aria-label": "how to correct your information permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h3>
      <p>{`If you believe that inaccurate or incorrect information has been provided to us, we encourage you first to engage with your credit provider to provide them with an opportunity to correct the information on their system and ours. `}</p>
      <p>{`Failing that, to correct your information, please register for a TaleFin account `}<a parentName="p" {...{
          "href": "https://login.talefin.com/Account/Register"
        }}>{`here`}</a>{`, or login to your existing account `}<a parentName="p" {...{
          "href": "https://login.talefin.com/Account/Login?ReturnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3Dlendguardwebconsumer%26redirect_uri%3Dhttps%253A%252F%252Fapp.talefin.com%252Fsignin-callback.html%26response_type%3Dcode%26scope%3Dopenid%2520profile%2520lendguard%2520lendguardapi%2520offline_access%26state%3D9d561b2325994eb6af06d25a596578bd%26code_challenge%3DCgDvb-v8tIgSMm_ffz619IEZhJK546S5oVN2ivw2wu4%26code_challenge_method%3DS256%26response_mode%3Dquery"
        }}>{`here`}</a>{`, where you will be able to log a correction request via our online portal. `}</p>
      <p>{`Information provided will be reviewed, and where applicable an investigation will take place: `}</p>
      <ul>
        <li parentName="ul">{`Through our investigation, we may contact you to seek additional information or clarification. `}</li>
        <li parentName="ul">{`We will also be in touch with the entity that provided your information for further clarification. `}</li>
      </ul>
      <p>{`For requests that require investigation, we will respond to you in writing, the outcome of the investigation and where applicable will immediately make the resultant corrections. `}</p>
      <p>{`In the event that the Credit Provider and TaleFin have failed to meet your satisfaction you may contact: `}</p>
      <ul>
        <li parentName="ul">{`Australian Financial Complaints Authority (AFCA) `}
          <ul parentName="li">
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://www.afca.org.au"
              }}>{`https://www.afca.org.au`}</a>{` `}</li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "mailto:info@afca.org.au"
              }}>{`info@afca.org.au`}</a>{` `}</li>
            <li parentName="ul">{`1800 931 678 `}</li>
            <li parentName="ul">{`GPO Box 3, Melbourne Vic 3000 `}</li>
          </ul>
        </li>
        <li parentName="ul">{`Office of the Australian Information Commissioner (OAIC) `}
          <ul parentName="li">
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://www.oaic.gov.au"
              }}>{`https://www.oaic.gov.au`}</a>{` `}</li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "mailto:enquiries@oaic.gov.au"
              }}>{`enquiries@oaic.gov.au`}</a>{` `}</li>
            <li parentName="ul">{`1300 363 992 `}</li>
            <li parentName="ul">{`GPO Box 5218, Sydney NSW 2001 `}</li>
          </ul>
        </li>
      </ul>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      